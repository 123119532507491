import React, { Component } from 'react';

export default class Menu extends Component {
	static displayName = Menu.name;

	render() {
		return (
			<div id="ktech_menu">
				<ul>
					<li><a href="/">Products</a></li>
				</ul>
			</div>
		);
	}
}