import React, { Component } from 'react';

export default class Privacy extends Component {
	static displayName = Privacy.name;

	render() {
		return (
			<React.Fragment>
				<h3>Privacy policy</h3>
				Privacy policy is available <a href="PrivacyPolicy">here</a>.
			</React.Fragment>
		);
	}
}