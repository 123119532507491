import React, { Component } from 'react';

export default class Home extends Component {
	static displayName = Home.name;

	render() {
		return (
			<div id="ktech_content">
				<h2><span style={{ color: "orangered" }}>Money Tracker</span></h2>
				<div className="col_w270 float_l">
					<h2>Windows 10/11</h2>
					<div className="image_wrapper" style={{ padding: 0 }}>
						<a href="AppWindows" rel="nofollow" target="_parent">
							<img src={require('../images/ScreenshotWindows.png')} alt="Money Tracker for Windows 10/11" />
						</a>
					</div>
					<p><b>Flexible and powerful</b> personal finance manager. Track your expenses, schedule payments, analyze and forecast even offline.</p>
					<div className="button"><a href="AppWindows" rel="nofollow" target="_parent">More...</a></div>
				</div>
				<div className="col_w270 float_l">
					<h2>Android</h2>
					<div className="image_wrapper" style={{ padding: 0 }}>
						<a href="AppAndroid" rel="nofollow" target="_parent">
							<img src={require('../images/ScreenshotAndroid.png')} alt="Money Tracker for Android" />
						</a>
					</div>
					<p>Personal finance manager for Android with data synchronization with <b>Windows 10/11</b> and <b>iOS</b>.</p>
					<div className="button"><a href="AppAndroid" rel="nofollow" target="_parent">More...</a></div>
				</div>
				<div className="col_w270 float_l">
					<h2>iOS</h2>
					<div className="image_wrapper" style={{ padding: 0 }}>
						<a href="AppApple" rel="nofollow" target="_parent">
							<img src={require('../images/ScreenshotApple.png')} alt="Money Tracker for iOS" />
						</a>
					</div>
					<p>Personal finance manager for iOS with data synchronization with <b>Windows 10/11</b> and <b>Android</b>.</p>
					<div className="button"><a href="AppApple" rel="nofollow" target="_parent">More...</a></div>
				</div>
			</div>
		);
	}
}