import * as React from 'react';
import Home from './components/Home';
import Stats from './components/Stats';
import AppWindows from './components/AppWindows';
import AppAndroid from './components/AppAndroid';
import AppApple from './components/AppApple';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReconciliationHelp from './components/ReconciliationHelp';
import SubscriptionAgreement from './components/SubscriptionAgreement';

const AppRoutes = [
	{
		index: true,
		element: <Home />
	},
	{
		path: '/Stats',
		element: <Stats />
	},
	{
		path: '/AppApple',
		element: <AppApple />
	},
	{
		path: '/AppWindows',
		element: <AppWindows />
	},
	{
		path: '/AppAndroid',
		element: <AppAndroid />
	},
	{
		path: '/PrivacyPolicy',
		element: <PrivacyPolicy />
	},
	{
		path: '/ReconciliationHelp',
		element: <ReconciliationHelp />
	},
	{
		path: '/SubscriptionAgreement',
		element: <SubscriptionAgreement />
	}
];

export default AppRoutes;