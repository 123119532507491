import React, { Component } from 'react';
import Overview from './Overview';
import UserGuides from './UserGuides';
import Privacy from './Privacy';

export default class AppAndroid extends Component {
	static displayName = AppAndroid.name;

	render() {
		return (
			<div id="ktech_content">
				<h2><span style={{ color: "orangered" }}>Money Tracker Infinite</span> for Android</h2>

				<h3>How to install</h3>

				<div><a href='https://play.google.com/store/apps/details?id=com.ktechsystems.moneytrackerpro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' rel="nofollow" target="_parent"><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width="160" style={{ marginLeft: -10, marginTop: -10 }} /></a></div>

				<div className="cleaner_h30"></div>

				<h3>Help</h3>

				<UserGuides/>

				<div className="cleaner_h30"></div>

				<Overview/>

				<div className="cleaner_h30"></div>

				<Privacy/>

				<div className="cleaner_h30"></div>

				<h3 id="H1">Screenshots</h3>

				<p>All screenshots are clickable:</p>

				<a href={require('../images/help/Android/S01.png')}><img src={require('../images/help/Android/thumbs/S01.png')} alt="Screenshot 1" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S02.png')}><img src={require('../images/help/Android/thumbs/S02.png')} alt="Screenshot 2" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S03.png')}><img src={require('../images/help/Android/thumbs/S03.png')} alt="Screenshot 3" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S04.png')}><img src={require('../images/help/Android/thumbs/S04.png')} alt="Screenshot 4" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S05.png')}><img src={require('../images/help/Android/thumbs/S05.png')} alt="Screenshot 5" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S06.png')}><img src={require('../images/help/Android/thumbs/S06.png')} alt="Screenshot 6" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S07.png')}><img src={require('../images/help/Android/thumbs/S07.png')} alt="Screenshot 7" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S08.png')}><img src={require('../images/help/Android/thumbs/S08.png')} alt="Screenshot 8" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S09.png')}><img src={require('../images/help/Android/thumbs/S09.png')} alt="Screenshot 9" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S10.png')}><img src={require('../images/help/Android/thumbs/S10.png')} alt="Screenshot 10" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S11.png')}><img src={require('../images/help/Android/thumbs/S11.png')} alt="Screenshot 11" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S12.png')}><img src={require('../images/help/Android/thumbs/S12.png')} alt="Screenshot 12" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/Android/S13.png')}><img src={require('../images/help/Android/thumbs/S13.png')} alt="Screenshot 13" style={{ marginRight: 3, marginBottom: 3 }} /></a>
			</div>
		);
	}
}