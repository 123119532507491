import React, { Component } from 'react';

export default class SubscriptionAgreement extends Component {
	static displayName = SubscriptionAgreement.name;

	render() {
		return (
			<div id="ktech_content">
				<h3>Terms for paid plans</h3>
				Last updated: November 23, 2019<br />
				1. Rights to use Additional Services of the Website are granted to the End User in accordance with the selected plan depending on the name, composition, and scope of rights granted.<br />
				2. Payment for rights to use Additional Services of the Website is made by the End User in the form of one-time payments or a subscription.<br />
				3. Subscriptions are renewed automatically if auto-renewal is not turned off no later than 24 hours prior to the expiration of the current subscription period.<br />
				4. Payment for subscription renewals will be made in the 24 hours prior to the expiration of the current subscription period. The amount charged for the renewal will be shown.<br />
				5. The subscription is managed by the End User, who can turn off auto-renewal after making a payment in the Dashboard. Learn more about cancelling subscriptions here <a href="https://support.apple.com/en-us/HT202039">https://support.apple.com/en-us/HT202039</a><br />
				6. Payment is made with non-cash transfers with the End User’s bank card, with mobile apps, as well as other methods specified on the Licensor’s website.<br />
				7. Payment for the rights to use Additional Services is made by the End User by making advance payments.<br />
				8. Payment to the iTunes Account is charged when the purchase is confirmed.<br />
				9. Any unused part of the free period (if offered in the paid plan) will be lost when the End User buys a subscription to this paid plan, if applicable.<br />
				10. The Licensor reserves the right to change paid plans, terms, and payment methods. The Licensor shall, if possible, notify the End User about changes no less than 10 days before the introduction of new rates.<br />
				<br /><h3>Description of paid plans</h3>
				12-month subscription to Money Tracker Premium<br />
				Name: Money Tracker Premium<br />
				Duration: 12 months<br />
				Price: 5.99 dollars (USD) for 12 months<br />
				Type: auto-renewable<br />
			</div>
		);
	}
}