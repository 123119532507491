import React, { Component } from 'react';
import { Container } from 'reactstrap';
import Header from './Header';
import Menu from './Menu';
import Intro from './Intro';
import Footer from './Footer';

export class Layout extends Component {
	static displayName = Layout.name;

	render() {
		return (
			<div id="ktech_wrapper">
				<Header />
				<Menu />
				<Intro />
				<div id="ktech_main">
					<span className="main_top"></span>
					<Container>
						{this.props.children}
					</Container>
					<div className="cleaner"></div>
				</div>
				<Footer />
			</div>
		);
	}
}