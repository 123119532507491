import React, { Component } from 'react';
import Overview from './Overview';
import UserGuides from './UserGuides';
import Privacy from './Privacy';

export default class AppWindows extends Component {
	static displayName = AppWindows.name;

	render() {
		return (
			<div id="ktech_content">
				<h2><span style={{ color: "orangered" }}>Money Tracker Infinite</span> for Windows 10/11</h2>

				<UserGuides/>

				<div className="cleaner_h30"></div>

				<Overview/>

				<div className="cleaner_h30"></div>

				<h3>How to install</h3>

				<div><a href='//www.microsoft.com/store/apps/9P58PP4LLSCP?cid=storebadge&ocid=badge'><img src='https://developer.microsoft.com/store/badges/images/English_get-it-from-MS.png' alt='Download app from Microsoft Store' style={{ width: 284, height: 104 }}/></a></div>

				<div className="cleaner_h30"></div>

				<Privacy/>

				<div className="cleaner_h30"></div>

				<h3 id="H1">Screenshots</h3>

				<p>All screenshots are clickable:</p>

				<a href={require('../images/help/W10/S01.png')}><img src={require('../images/help/W10/thumbs/S01.png')} alt="Screenshot 1" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S02.png')}><img src={require('../images/help/W10/thumbs/S02.png')} alt="Screenshot 2" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S03.png')}><img src={require('../images/help/W10/thumbs/S03.png')} alt="Screenshot 3" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S04.png')}><img src={require('../images/help/W10/thumbs/S04.png')} alt="Screenshot 4" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S05.png')}><img src={require('../images/help/W10/thumbs/S05.png')} alt="Screenshot 5" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S06.png')}><img src={require('../images/help/W10/thumbs/S06.png')} alt="Screenshot 6" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S07.png')}><img src={require('../images/help/W10/thumbs/S07.png')} alt="Screenshot 7" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S08.png')}><img src={require('../images/help/W10/thumbs/S08.png')} alt="Screenshot 8" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/W10/S09.png')}><img src={require('../images/help/W10/thumbs/S09.png')} alt="Screenshot 9" style={{ marginRight: 3, marginBottom: 3 }} /></a>
			</div>
		);
	}
}