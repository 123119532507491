import React, { Component } from 'react';

export default class ReconciliationHelp extends Component {
	static displayName = ReconciliationHelp.name;

	render() {
		return (
			<div id="ktech_content">
				<h3>Reconciliation process</h3>

				<p>
					Clearance states:<br />
					<b>Unreconciled (<span style={{ fontSize: 22 }}>&#x25CB;</span>)</b> - transaction has just been entered and not yet matched with online banking or bank statement<br />
					<b>Cleared (<span style={{ fontSize: 15 }}>&#x25D0;</span>)</b> - transaction has been matched with bank statement or online banking<br />
					<b>Reconciled (<span style={{ fontSize: 24 }}>&#x25CF;</span>)</b> - bank statement balance(usually monthly) matches app account balance for that date, which means all past transaction are reconciled and will never change <br />
					<b>Void (<span style={{ fontSize: 20 }}>&#x25CC;</span>)</b > - transaction has been rejected / cancelled and its amount is not included into account balance <br />
				</p>

				<br />

				<p>
					Reconciliation is only needed for accounts that have statements like bank accounts, current, saving, credit card, etc.
					Cash accounts do not need reconciliation and you may want to edit such accounts in the app and enable autoreconciliation, which means all new transactions will have <b>Reconciled</b> state by default.
				</p>

				<br />
				Here is how reconciliation works in the app (R - reconciled transaction, <b><span style={{ color: "red" }}>R</span></b> - last reconciled transaction, C - cleared, <b><span style={{ color: "red" }}>C</span></b> - last cleared transaction, U - unreconciled).

				<br />
				<br />
				Before reconciliation:<br />
				<b>past</b> RRRRRRRRRRRRRR<b><span style={{ color: "red" }}>R</span></b>UUUUUUUU <b>present</b>
				<br /><br />
				When "Done" button is enabled:<br />
				<b>past</b> RRRRRRRRRRRRRR<b><span style={{ color: "red" }}>R</span></b>CCCC<b><span style={{ color: "red" }}>C</span></b>UUU <b>present</b>
				<br /><br />
				After "Done" button is pressed:<br />
				<b>past</b> RRRRRRRRRRRRRRRRRRR<b><span style={{ color: "red" }}>R</span></b>UUU <b>present</b>

				<br />
				<br />
				For reconciliation to be successful balance of <b><span style={{ color: "red" }}>R</span></b> must be equal to <b>Starting balance</b>. Balance of <b><span style={{ color: "red" }}>C</span></b> must be equal to <b>Ending balance</b>.

				<br />
				<br />
				Sample scenario:
				<ul>
					<li>New tranasactions are entered as <b>Unreconciled</b>;</li>
					<li>At the end of the months after a transaction has been found in bank statement (or earlier if you received bank notification that transaction went through) it can be marked as <b>Cleared</b>;</li>
					<li>When all statement transactions are cleared and statement ending balance matches <b>Ending balance</b> in the app then <b>Done</b> button is enabled and when clicked all cleared transactions will change state to <b>Reconciled</b>;</li>
					<li>Latest <b>Reconciled</b> balance is then remembered by the app and will be used next month as <b>Starting balance</b>.</li>
				</ul>

				<b>Cleared balance</b> is a sum of all <b>Reconciled</b> and <b>Cleared</b> transactions before and including <b>Statement date</b>.
				<br />
				<b>Reconciled balance</b> is a sum of all <b>Reconciled</b> transactions.
			</div>
		);
	}
}