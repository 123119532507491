import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
	static displayName = PrivacyPolicy.name;

	render() {
		return (
			<div id="ktech_content">
				<h3>Privacy Policy for Money Tracker (Windows Phone 8.1, Windows 10/11, Android and iOS) and related applications</h3>

				<p>All collected financial information is stored only on user device and not available to any other party. Backup and syncronization files are stored in cloud storage services, available only to authorised users of corresponding cloud storage accounts and subject to Microsoft (OneDrive) and Dropbox privacy policy.</p>
				<p>The only information available to app developer is application usage statistics such as visited application screens and error reports used to improve application quality. There is no way to identify a person using this statistics. All information is collected via Microsoft App Center technology and stored on Microsoft servers.</p>
				<p>To prevent unauthorized access to financial data stored on a user device it is recommended to protect the application data with a PIN or password.</p>
			</div>
		);
	}
}