import React, { Component } from 'react';

export default class Footer extends Component {
	static displayName = Footer.name;

	render() {
		return (
			<React.Fragment>
				<div id="ktech_main_bottom"/>
				<div id="ktech_footer" style={{ textAlign: 'center' }}>
					Copyright © 2013-2024 <a href="#">Vitaly Knyazev</a>
				</div>
			</React.Fragment>
		);
	}
}