import React, { Component } from 'react';

export default class UserGuides extends Component {
	static displayName = UserGuides.name;

	render() {
		return (
			<React.Fragment>
				<h3>
					<span style={{ color: "dodgerblue" }}>User guide: </span><a href="./User_Guide_En.pdf" target="_blank">English</a>
					<span style={{ color: "dodgerblue" }}>, </span><a href="./User_Guide_Es.pdf" target="_blank">Español</a>
					<span style={{ color: "dodgerblue" }}>, </span><a href="./User_Guide_PtBr.pdf" target="_blank">Português</a>
				</h3>
				<h3><span style={{ color: "dodgerblue" }}>How to: move data from Microsoft Money </span><a href="./HowToMigrateFromMicrosoftMoney.pdf" target="_blank">Download</a></h3>
				<h3><span style={{ color: "dodgerblue" }}>How to: move data from Quicken </span><a href="./HowToMigrateFromQuicken.pdf" target="_blank">Download</a></h3>
			</React.Fragment>
		);
	}
}