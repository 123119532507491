import React, { Component } from 'react';
import { Follow } from 'react-twitter-widgets'

export default class Header extends Component {
	static displayName = Header.name;

	render() {
		return (
			<div id="ktech_header">
				<div className="site_desc" style={{ right: 270, top: 53 }}>
					<a href="mailto:support@k-tech-systems.com">support@k-tech-systems.com</a>
				</div>

				<div className="site_desc">
					<Follow username='KTechSystemsLtd' options={{ count: "none" }} />
				</div>

				{/*<div id="site_title">*/}
				{/*	<h1><a href="/" rel="nofollow"><span>optimal solutions</span></a></h1>*/}
				{/*</div>*/}
			</div>
		);
	}
}