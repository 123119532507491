import React, { Component } from 'react';

export default class Overview extends Component {
	static displayName = Overview.name;

	render() {
		return (
			<React.Fragment>
				<h3 id="overview">Overview</h3>
				<p>Money Tracker is a replacement for popular finance managers like Quicken, Microsoft Money, SPB Finance.</p>
				<p>Create accounts, add and schedule transactions, configure reports and budgets, analyze and forecast your cashflows using this flexible application, even offline.</p>
				<p>Simple sync between various devices on Windows 10/11, Android/iOS (tablets and phones) via Dropbox/OneDrive.</p>
			</React.Fragment>
		);
	}
}