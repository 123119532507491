import React, { Component } from 'react';
import Overview from './Overview';
import UserGuides from './UserGuides';
import Privacy from './Privacy';

export default class AppApple extends Component {
	static displayName = AppApple.name;

	render() {
		return (
			<div id="ktech_content">
				<h2><span style={{ color: "orangered" }}>Money Tracker Infinite</span> for iOS</h2>

				<h3>How to install</h3>

				<div><a href='https://apps.apple.com/us/app/money-tracker/id1643407103#?platform=iphone' rel="nofollow" target="_parent"><img alt='Get it on App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png' /></a></div>

				<div className="cleaner_h30"></div>

				<h3>Help</h3>

				<UserGuides/>

				<div className="cleaner_h30"></div>

				<Overview/>

				<div className="cleaner_h30"></div>

				<Privacy/>

				<div className="cleaner_h30"></div>

				<h3 id="H1">Screenshots</h3>

				<p>All screenshots are clickable:</p>

				<a href={require('../images/help/iOS/S01.png')}><img src={require('../images/help/iOS/thumbs/S01.png')} alt="Screenshot 1" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S02.png')}><img src={require('../images/help/iOS/thumbs/S02.png')} alt="Screenshot 2" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S03.png')}><img src={require('../images/help/iOS/thumbs/S03.png')} alt="Screenshot 3" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S04.png')}><img src={require('../images/help/iOS/thumbs/S04.png')} alt="Screenshot 4" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S05.png')}><img src={require('../images/help/iOS/thumbs/S05.png')} alt="Screenshot 5" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S06.png')}><img src={require('../images/help/iOS/thumbs/S06.png')} alt="Screenshot 6" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S07.png')}><img src={require('../images/help/iOS/thumbs/S07.png')} alt="Screenshot 7" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S08.png')}><img src={require('../images/help/iOS/thumbs/S08.png')} alt="Screenshot 8" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S09.png')}><img src={require('../images/help/iOS/thumbs/S09.png')} alt="Screenshot 9" style={{ marginRight: 3, marginBottom: 3 }} /></a>
				<a href={require('../images/help/iOS/S10.png')}><img src={require('../images/help/iOS/thumbs/S10.png')} alt="Screenshot 10" style={{ marginRight: 3, marginBottom: 3 }} /></a>
			</div>
		);
	}
}