import React, { Component } from 'react';

export default class Intro extends Component {
	static displayName = Intro.name;

	render() {
		return (
			<div id="ktech_banner">
				<div className="cs_article">
					<div className="slider_content_wrapper">
						<div className="left logo_image_wrapper">
							<img src={require('../images/MoneyTrackerPro_Tile_Large.png')} style={{ width: 142, height: 142 }} alt="Money Tracker logo" />
						</div>
						<div className="right">
							<p>Advanced personal finance manager with data sync across devices</p>

							<h3>Money Tracker for <a href="AppWindows" className="header_link">Windows 10/11</a>, <a href="AppAndroid" className="header_link">Android</a> and <a href="AppApple" className="header_link">iOS</a></h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}